<template>
    <div>
        <img class="example-headImg" src="../assets/image/example/02-5banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">智慧疾病控制</div>
            <div class="head-explain-content">个性化、精准化、智能化和网络化</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">解决方案</span></div>
            <div class="head-list-item"><span class="title">方案收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-content">传染病多渠道检测</div>
                <div class="purchase-content">智慧应急指挥调度</div>
                <div class="purchase-content">智能流行病学调查</div>
                <div class="purchase-content">重点专病监测平台</div>
                <div class="purchase-content">智慧疾控综管平台</div>
                <div class="purchase-content">死因监测综合管理</div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 70rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">解决方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../assets/image/example/02-5多渠道监测.png"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">方案收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                  <div class="card">
                    <img class="logo" src="../assets/image/example/02-5成本.svg"/>
                    <div class="title1">监测<img class="up-down" src="../assets/image/example/上升.svg"/></div>
                    <div class="title2">81%</div>
                    <div class="title3">传染病多渠道监测</div>
                  </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-5成本.svg"/>
                        <div class="title1">效率<img class="up-down" src="../assets/image/example/上升.svg"/></div>
                        <div class="title2">70%</div>
                        <div class="title3">数据分析效率提高</div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-5溯源.svg"/>
                        <div class="title1">溯源<img class="up-down" src="../assets/image/example/上升.svg"/></div>
                        <div class="title2">87%</div>
                        <div class="title3">溯源效率提高</div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../assets/image/example/02-5数据.svg"/>
                        <div class="title1">数据<img class="up-down" src="../assets/image/example/上升.svg"/></div>
                        <div class="title2">100%</div>
                        <div class="title3">数据准确性实时性</div>
                    </div>
                </div>
                <div class="purchase-title" style="margin-top:4rem">应急指挥调度</div>
                <div class="purchase-content">统一平台、统一通信、统一部署、统一指挥、统一调度</div>
                <div class="purchase-title">流行病学调查</div>
                <div class="purchase-content">早发现、早报告、早诊断、早隔离、早治疗</div>
                <div class="purchase-title">专病监测</div>
                <div class="purchase-content">全面提升重点疾病的防制能力和科学决策水平</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.9rem;
    height: 59.9rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    /* flex-wrap: wrap; */
    justify-content: space-between;

    .card{
        width: 25.6rem;
        height: 26.5rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
        justify-content: center;
        .logo{
            margin: 3rem 0 0 9.6rem;
        }
        .up-down{
            margin-top: 1rem;
            margin-left: 0.4rem;
            width: 1.6rem;
            height: 1.6rem;
        }
        .title1{
            width: 6rem;
            height: 2rem;
            font-size: 2rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;
            display: flex;

            margin: auto;
            margin-top: 2.2rem;
        }
        .title2{
            width: 6.1rem;
            height: 3rem;
            font-size: 3rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 4.5rem;

            margin: auto;
            margin-top: 2.8rem;
        }
        .title3{
            // width: 9.6rem;
            text-align: center;
            height: 1.6rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;
            margin: auto;
            margin-top: 0.7rem;
        }
    }
}
</style>
